<template>
  <div class="compare">
    <Layout src="" :isLoading="isLoading" :msg="msg" bgColor="#d30d30">
      <div class="pic"><img src="/static/images/title.png" /></div>
      <CompareList
        :datas="dataList.return_up_data"
        v-if="!isLoading && dataList.return_up_data.length > 0"
      >
      </CompareList>
      <CompareList
        :datas="dataList.return_down_data"
        v-if="!isLoading && dataList.return_down_data.length > 0"
      >
      </CompareList>
      <div class="pic"><img src="/static/images/bottom.png" /></div>
    </Layout>
    <div class="move" v-move @click="back" ref="move">
      <span>返</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { moveDom } from "@/directives/moveDom";
import Layout from "@/pages/wechatNirvana/components/layout.vue";
import CompareList from "@/pages/wechatNirvana/components/compareList.vue";
import { apiGetPkDisparity } from "@/api/modules/app";

export default defineComponent({
  components: {
    Layout,
    CompareList,
  },
  directives: {
    move: moveDom,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { pk_type, shr_number, group_key, stage } = route.query;

    const getNirvanaDisparity = () => {
      apiGetPkDisparity({
        pk_type: pk_type,
        shr_number: shr_number,
        group_key: group_key,
        stage: stage,
      })
        .then((res: any) => {
          if (res.status == 200) {
            dataMap.dataList = res.data_list;
            dataMap.isLoading = false;
          }
        })
        .catch((err) => {
          console.log("err");
        });
    };

    const dataMap = reactive({
      dataList: {} as GetNirvanaDisparityReq,
      msg: "数据加载中...",
      isLoading: true,
    });

    getNirvanaDisparity();

    const back = () => {
      router.push("wechatPk");
    };
    return {
      ...toRefs(dataMap),
      back,
    };
  },
});
</script>

<style lang="less" scoped>
.compare {
  .pic {
    margin: 20px;
  }

  .move {
    position: fixed;
    top: 80%;
    right: 20px;

    span {
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 20px;
      background-color: #009a61;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
</style>
