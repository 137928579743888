
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { moveDom } from "@/directives/moveDom";
import Layout from "@/pages/wechatNirvana/components/layout.vue";
import CompareList from "@/pages/wechatNirvana/components/compareList.vue";
import { apiGetPkDisparity } from "@/api/modules/app";

export default defineComponent({
  components: {
    Layout,
    CompareList,
  },
  directives: {
    move: moveDom,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { pk_type, shr_number, group_key, stage } = route.query;

    const getNirvanaDisparity = () => {
      apiGetPkDisparity({
        pk_type: pk_type,
        shr_number: shr_number,
        group_key: group_key,
        stage: stage,
      })
        .then((res: any) => {
          if (res.status == 200) {
            dataMap.dataList = res.data_list;
            dataMap.isLoading = false;
          }
        })
        .catch((err) => {
          console.log("err");
        });
    };

    const dataMap = reactive({
      dataList: {} as GetNirvanaDisparityReq,
      msg: "数据加载中...",
      isLoading: true,
    });

    getNirvanaDisparity();

    const back = () => {
      router.push("wechatPk");
    };
    return {
      ...toRefs(dataMap),
      back,
    };
  },
});
